.form-wrapper-contact {
  max-width: 900px;
  margin: 0em auto;

  form {
    // border: 2px solid black;
    // border-radius: 1em;
    line-height: 2em;

    h2 {
      text-align: center;
      // color: orangered;
      font-size: 1.5em;
      margin: 1em 0;
      font-weight: 600;
    }
    padding: 1em;
    color: inherit;

    p {
      // font-family: Helvetica;
    }
    button {
      background: orangered;
      color: white;
      border:none;
      box-shadow: none;
      width: 5em;
    }
  }
}
